<template>
    <div class="agb-view footer-margin do-not-translate">

        <div class="container footer-margin" v-if="terms!==null" v-html="terms">

        </div>
    </div>
</template>

<script>
    import Resource from '@/api/resource';
    const serviceTermResource = new Resource('serviceTerms');
    import { showLoader, hideLoader } from '@/utils/helpers';

    export default {
        name: 'AgbView',
        data() {
            return {
                loader: null,
                content: null,
            }
        },
        computed: {
            terms() {
                if(this.content!==null && Object.prototype.hasOwnProperty.call(this.content,'terms')){
                    return this.content.terms;
                }
                return null;
            },
        },
        created() {
          this.getLatestServiceTerm();
      },
      methods: {
        showLoader, 
        hideLoader,
        getLatestServiceTerm() {
            this.loader = this.showLoader(this.loader);
            serviceTermResource.list()
            .then(response => {
                this.content = response.data;
            })
            .finally(() => {
              this.loader = this.hideLoader(this.loader);
          });
        },
    },
}
</script>

<style lang="scss">
    @import '@/scss/_variables.scss';

    .agb-view{
        position: relative;
        background: #fff;
        padding-top: 60px;
        padding-bottom: 20px;

        margin-bottom: calc(#{$footer-sm-size} + #{$mobile-menu-size});

        @media (max-width: 991px) {
          margin-bottom: #{$mobile-menu-size}
      }

      p{
        margin-bottom: 10px;
      }

  }

</style>
