export function normalizeString(string){
    if(string!==null && string !== undefined && string !== ''){
        return string.replace(/[^A-Z0-9]+/ig, "_").toLowerCase();
    }
    return "";
}

    /**
 * decode with try-catch
 */
 export function json_decode(elem){
    try {  
        return JSON.parse(elem);
    } catch (e) {
        return elem;  
    }
}

/** 
 * decode flyer URL
*/
export function getFlyerLink(flyer, backendUrl){
    if(flyer != null){
        flyer = json_decode(flyer);
        if(typeof flyer === "object"){
            return backendUrl+flyer.path;
        } 
        else {
          flyer = flyer.charAt(0)!="/"?"/"+flyer:flyer;
          return backendUrl+flyer;
      }
  }
  return null;
}

/**
 * get the file path from a json object
 */
 export function getFilePath(file){
    try {  
        var elem = json_decode(file);
        if(typeof elem === "object"){
            /*it's an object with copyright etc.*/
            return elem.path;
        }
        else{
            /*it's an old style image*/
            return file;
        }
    } catch (e) {
        console.log(e);
        return file;  
    }
 }

export function getFieldValues(content,tname){
    if(content!=null && Object.prototype.hasOwnProperty.call(content,'fieldTypes') && content.fieldTypes!=null){
        /*find a specific field*/
        for(var i = 0; i < content.fieldTypes.length; i++){
            var field = content.fieldTypes[i];
            if(Object.prototype.hasOwnProperty.call(field,'technical_name') && field.technical_name==tname){
                if(field.value!=null && Array.isArray(field.value) && field.value.length==1){
                    return field.value[0];
                }
                return field.value;
            }
        }
    }
    return null;
}

export function getBasicFilters(structuredFilters){
    var filters = [];
    if(structuredFilters!==null){
        for(var i = 0; i < structuredFilters.length; i++){
            var blub = structuredFilters[i];
            if(structuredFilters[i].type == "depender"){
                var gna = {};
                gna.field_type_id=blub.field_type_id;
                gna.label = blub.label;
                gna.options = []
                for(var j = 0; j < blub.options.length; j++){
                    gna.options.push({label:blub.options[j].label,value:blub.options[j].label});
                }
                filters.push(gna);
            }
            else{
                filters.push(blub);
            }        
        }
    }
    return filters;
}

/*check if an array of booleans contains one that is true ...*/
export function hasErrors(objectOfBooleans){
    for (const [key, value] of Object.entries(objectOfBooleans)) {
        if(value==true){
            return true;
        }
    }
    return false;
}


/*changes the route-query*/
export function changeRouteQuery(route,query) {
    history.pushState({},null,
        route.path +
        '?' +
        Object.keys(query)
        .map(key => {
          return (encodeURIComponent(key) + '=' + encodeURIComponent(query[key]))
      })
        .join('&')
        )
}



/**
 * a function that receives a stringified map that was converted to an array as a url-query and converts it back into a map
 */
 export function argsFilterMapToJSMap(args){
    var filters = null;
    if(isset(args)){
        /*decode them*/
        filters = decodeURIComponent(args);
        /*this should give us something better than a string*/
        filters = JSON.parse(filters);
    }

    var filterMap = new Map();
    if(isset(filters)&&filters.length>0){
        for(var i = 0; i<filters.length; i++){
            filterMap.set(filters[i][0],filters[i][1]);
        }
        return filterMap;
    }
    return null;
}

export function getFirstFieldValue(content,tname){
    var values =  getFieldValues(content,tname);
    if(Array.isArray(values) && values.length>0){
        return values[0];
    }
    return values;
}

export function getAllowedFieldValues(content,tname){
    if(content!=null && Object.prototype.hasOwnProperty.call(content,'fieldTypes') && content.fieldTypes!=null){
        /*find a specific field*/
        for(var i = 0; i < content.fieldTypes.length; i++){
            var field = content.fieldTypes[i];
            return getAllowedValuesFromFieldTypes(field);
        }
    }
    return null;
}

export function getAllowedValuesFromFieldType(fieldType){
    if(Object.prototype.hasOwnProperty.call(fieldType,'field_type_values')){
        var allowed = Array();
        /*we need only the allowed values*/
        for(var j = 0; j < fieldType.field_type_values.length; j++){
            allowed.push(fieldType.field_type_values[j].allowed_value);
        }
        return allowed;
    }
    return null
}

export function getFilterFields(content){
    var fields = [];
    if(content!=null && Object.prototype.hasOwnProperty.call(content,'fieldTypes') && content.fieldTypes!=null){
        /*find a specific field*/
        for(var i = 0; i < content.fieldTypes.length; i++){
            var field = content.fieldTypes[i];
            if(Object.prototype.hasOwnProperty.call(field,'type')){
                if(field.type === 'select' || field.type === 'multiselect' || field.type === 'expandable_multiselect'){
                    fields.push(field);
                }
            }
        }
    }
    return fields.length>0?fields:null;
}

export function getStructuredFilters(contentType){
    var filters = [];
    var structuredFilters = [];
    var filterFields = getFilterFields(contentType);
    /*iterate through those fields*/
    if(filterFields!==null){

        for(var i = 0; i < filterFields.length; i++){

            if(filterFields[i].dependencySettings==null){ 
                var entity = {};
                entity.field_type_id = filterFields[i].id;
                entity.label = filterFields[i].name;
                var values = getAllowedValuesFromFieldType(filterFields[i]);
                var dependees = getDependees(filterFields[i],values,filterFields);
                if(dependees!==null && dependees.length>0){
                    entity.type = "depender";
                    entity.options = dependees;
                }
                else{
                    entity.type = "normal";
                    entity.options = createSelectOptions(values);
                }

                filters.push(entity);
            }
        }
    }

    return filters;
}


export function getDependees(forFieldType,forFieldTypeValues,fromFieldTypesArray){
    var dependees = [];
    for(var i = 0; i < fromFieldTypesArray.length; i++){
        if(fromFieldTypesArray[i].dependencySettings!==null && fromFieldTypesArray[i].dependencySettings.dependend_on == forFieldType.id && forFieldTypeValues.includes(fromFieldTypesArray[i].dependencySettings.action_value)){
         /*yes - we found a dependee*/
         var entity = {};
         entity.field_type_id = fromFieldTypesArray[i].id;
         entity.label = fromFieldTypesArray[i].dependencySettings.action_value;
         var subValues = getAllowedValuesFromFieldType(fromFieldTypesArray[i]);
         var subDependees = getDependees(fromFieldTypesArray[i],subValues,fromFieldTypesArray);
         if(subDependees.length==0){    
            entity.type="normal";                    
            entity.options = createSelectOptions(subValues);
        }
        else{
            entity.type="depender";
            entity.options = subDependees;
        }
        dependees.push(entity);
    }
}
return dependees;
}

export function createSelectOptions(values){
    if(values!==null && Array.isArray(values) && values.length>0){
        var options = [];
        for(var i = 0; i < values.length; i++){
            options.push({label:values[i],value:values[i]});
        }
        return options;
    }
    return null;
}

export function shortenUrl(url){
    if(url!=null){
      return url.length>26?url.substring(0,26)+'...':url;
  }
}

export function shortenString(str,length){
    if(str!=null){
      return str.length>length?str.substring(0,length)+'...':str;
  }
}

export function parseTravelTime(timeInMilliSeconds){
    var hours = null;
    var minutes = null;
    var totalTime = null;
    var timeInMinutes = timeInMilliSeconds/60000;
    if(timeInMinutes>60){
        hours = Math.round(timeInMinutes/60);
        minutes = timeInMinutes-(hours*60);
        if(minutes <= 0){
            totalTime = hours + ' h';
        }
        else{
            totalTime = hours + ' h '+Math.round(minutes)+' min';
        }
    }
    else{
        minutes = timeInMinutes;
        totalTime = Math.round(minutes) + ' min';
    }
    return totalTime;
}



export function smartSubStr(str, len) {
    var temp = str.substr(0, len);
    if(temp.lastIndexOf('<') > temp.lastIndexOf('>')) {
        temp = str.substr(0, 1 + str.indexOf('>', temp.lastIndexOf('<')));
    }
    return temp;
}

export function showLoader(loader){
    loader=this.$loading.show({
        container: this.fullPage ? null : this.$refs.formContainer,
        canCancel: true,
        color: "#007BFF",
        loader: 'dots',
        opacity:0.7,
        height:128,
        width:128,
        onCancel: this.onCancel,
    });
    return loader;
}


export function hideLoader(loader){
    loader.hide();
    return loader;
}


/**
 * sorts a given array of objects case-insensitive alphabetically and considering a certain locale  
 */
 export function sortByProperty(objects, property, langcode){ 
    objects.sort(function(a, b) {
       return a[property].toLowerCase().localeCompare(b[property].toLowerCase(),langcode);
   });
    return objects;
}


/**
 * get a placeholder image if no other image is given
 */
 export function getPlaceholderImage(category = null){
    /*we pack all the images in an array so we can return a random one*/
    var images = [
    "/assets/categories/bildungwissenschaft_default.png",
    "/assets/categories/kunstgalerie_default.png",
    "/assets/categories/musiktanz_default.png",
    "/assets/categories/cafesbarclub_default.png",
    "/assets/categories/filmmedien_default.png",
    "/assets/categories/kulturkreativraum_default.png",
    "/assets/categories/museumausstellung_default.png",
    "/assets/categories/religion_default.png",
    "/assets/categories/theateroper_default.png"];

    var image = null;

    /*if a category is given we'll look for an image matching that category*/
    if(category != null){
        switch(category){
            case "Bildung & Wissenschaft":
            image = images[0];
            break;
            case "Kunst & Galerien":
            image = images[1];
            break;
            case "Musik & Tanz":
            image = images[2];
            break;
            case "Cafés - Bars & Clubs":
            image = images[3];
            break;
            case "Film & Medien":
            image = images[4];
            break;
            case "Kultur- & Kreativräume":
            image = images[5];
            break;
            case "Museen & Stadtgeschichte":
            image = images[6];
            break;
            case "Religionskultur":
            image = images[7];
            break;
            case "Theater & Oper":
            image = images[8];
            break;
            default:
            image = images[Math.floor(Math.random()*images.length)];
            break;
        }
    }

    if(image == null){
        image = images[Math.floor(Math.random()*images.length)];
    }

    return image;
}

 /**
 * Extract the allowedFieldValues from a FieldTypeResource
 */
 export function getAllowedValuesFromFieldTypes(data){
    if(Object.prototype.hasOwnProperty.call(data,'field_type_values') && data.field_type_values!=null){
      /*we'll need to extract the actual values from that array*/
      var allowedValues = [];
      for(var i = 0; i<data.field_type_values.length; i++){
        var fTypeValueId = data.field_type_values[i].id;
        var value = data.field_type_values[i].allowed_value;
        if(value!=null && value != ""){
            allowedValues.push({'label': value, 'value': value, 'id':fTypeValueId});
        }
    }
    return allowedValues;
}
return null;
}

export function mapOrder(array, order, key) {
    var map = new Map();
    var index = 0;
    var tmp;
    if(!array || !order || array.length!==order.length)
      return array;
  array.forEach(function(it) {
    map.set(it[key], index++);
});
  order.forEach(function(it) {
      if(map.get(it) === undefined) return array;
  });
  index--;
  for (; index >= 0; index--) {
    if (array[index][key] !== order[index]) {
        tmp = array[index];
        array[index] = array[map.get(order[index])];
        array[map.get(order[index])] = tmp;
        map.set(tmp[key], map.get(order[index]));
    }
}
return array;
}

/**
 * checks if a varialbe has a usefull value  
 */
 export function isset(variable){
    if(variable!==null && variable!==undefined && variable!== ''){

        /*check if variable is string*/
        if(typeof variable === 'string'){
            if(variable.trim()!== ''){
                return true;
            }
            else{
                return false;
            }
        }

        return true;
    }

    return false;
}